import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { History, Location } from 'history';

import { AriaWrappedButtonFactory } from './AriaWrappedButtonFactory';
import { LinkContext } from '../../LinkContext';

export type ILinkButtonProps = {
  to?: History.LocationDescriptor<unknown> | ((location: Location<unknown>) => History.LocationDescriptor<unknown>),
  ref?: React.RefObject<HTMLAnchorElement>,
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkButtonAria = AriaWrappedButtonFactory<ILinkButtonProps>('a', (props) => {
  const { domainName } = useContext(LinkContext);

  function isExternal(url: string)
  {
    if(url.indexOf('/live') === 0) { // Live is a special case - separate application with a relative link
      return true;
    }
    try {
      const parsedUrl = new URL(url);

      // If the url hostname matches our current hostname then it's not an external link
      if(parsedUrl.hostname === domainName)
      {
        return false;
      }
    } catch (err)
    {
      /* If url parsing failed it may be due to a  relative url, which would be internal
       * any other reason for failure would be a malformed url
       * and determining the root cause is outside the scope of this function
       */
      return false;
    }

    /* If we made it this far it's an extrnal url */
    return true;
  }
  return (
    typeof(props.to) === 'string' && isExternal(props.to)
      ? (
        <a
          { ...props }
          href = { props.to }
          target = {props.target === undefined ? '_blank' : props.target}
          rel = {props.rel === undefined ? 'noopener nofollow' : props.rel}
          ref = { props.ref }
        >
          { props.children }
        </a>
      )
      : typeof(props.to) === 'string' && props.to.indexOf('api/') !== (-1)
        ? (
          <a
            { ...props }
            href = { props.to }
            ref = { props.ref }
          >
            { props.children }
          </a>
        )
        : (
          <Link
            { ...props }
            to = { props.to ||'#' }
            ref = { props.ref }
          >
            { props.children }
          </Link>
        )
  );
});
